import React, { useState, useEffect } from 'react';

const Header = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const heroHeight = document.querySelector('.Hero').offsetHeight;
      setIsCollapsed(window.scrollY > heroHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`Header ${isCollapsed ? 'collapsed' : ''}`}>
      <nav>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#meme">Meme</a></li>
          <li><a href="#roadmap">Roadmap</a></li>
          <li><a href="#faq">FAQ</a></li>
          <li><a href="#partners">Partners</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;