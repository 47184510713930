import React, { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { VOXLoader, VOXData3DTexture } from 'three/examples/jsm/loaders/VOXLoader';
import './Entry.css';

const Entry = () => {
  const [showContent, setShowContent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState('');
  const mountRef = useRef(null);
  const wiresRef = useRef(null);
  const smartContractRef = useRef(null);


  useEffect(() => {
    let renderer, scene, camera, controls, clock;
    let animationFrameId;

    const init = () => {
      renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      renderer.setSize(window.innerWidth, window.innerHeight);
      mountRef.current.appendChild(renderer.domElement);

      scene = new THREE.Scene();

      camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, 0, 500); 
      
      controls = new OrbitControls(camera, renderer.domElement);
      controls.autoRotate = true;
      controls.autoRotateSpeed = -1.0;
      controls.enableDamping = true;
      controls.enableZoom = true;
      controls.enablePan = true;

      clock = new THREE.Clock();

      const loadingManager = new THREE.LoadingManager();
      loadingManager.onLoad = () => {
        setIsLoading(false);
        const timer = setTimeout(() => {
          setShowContent(true);
        }, 2000);
        return () => clearTimeout(timer);
      };



    // Add countdown timer
    const countdownDate = new Date('October 25, 2024 12:00:00 UTC').getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);

      if (distance < 0) {
        clearInterval(timer);
        setTimeRemaining('EXPIRED');
      }
    };

    const timer = setInterval(updateCountdown, 1000);




      // Material
      const vertexShader = `
        in vec3 position;
        in mat4 instanceMatrix;

        uniform mat4 modelMatrix;
        uniform mat4 modelViewMatrix;
        uniform mat4 projectionMatrix;
        uniform vec3 cameraPos;

        out vec3 vOrigin;
        out vec3 vDirection;

        void main() {
          vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4( position, 1.0 );

          vOrigin = vec3( inverse( instanceMatrix * modelMatrix ) * vec4( cameraPos, 1.0 ) ).xyz;
          vDirection = position - vOrigin;

          gl_Position = projectionMatrix * mvPosition;
        }
      `;

      const fragmentShader = `
      precision highp float;
      precision highp sampler3D;
    
      uniform mat4 modelViewMatrix;
      uniform mat4 projectionMatrix;
    
      in vec3 vOrigin;
      in vec3 vDirection;
    
      out vec4 color;
    
      uniform sampler3D map;
    
      uniform float threshold;
      uniform float steps;
    
      vec2 hitBox( vec3 orig, vec3 dir ) {
        const vec3 box_min = vec3( - 0.5 );
        const vec3 box_max = vec3( 0.5 );
        vec3 inv_dir = 1.0 / dir;
        vec3 tmin_tmp = ( box_min - orig ) * inv_dir;
        vec3 tmax_tmp = ( box_max - orig ) * inv_dir;
        vec3 tmin = min( tmin_tmp, tmax_tmp );
        vec3 tmax = max( tmin_tmp, tmax_tmp );
        float t0 = max( tmin.x, max( tmin.y, tmin.z ) );
        float t1 = min( tmax.x, min( tmax.y, tmax.z ) );
        return vec2( t0, t1 );
      }
    
      float sample1( vec3 p ) {
        return texture( map, p ).r;
      }
    
      #define epsilon .0001
    
      vec3 normal( vec3 coord ) {
        if ( coord.x < epsilon ) return vec3( 1.0, 0.0, 0.0 );
        if ( coord.y < epsilon ) return vec3( 0.0, 1.0, 0.0 );
        if ( coord.z < epsilon ) return vec3( 0.0, 0.0, 1.0 );
        if ( coord.x > 1.0 - epsilon ) return vec3( - 1.0, 0.0, 0.0 );
        if ( coord.y > 1.0 - epsilon ) return vec3( 0.0, - 1.0, 0.0 );
        if ( coord.z > 1.0 - epsilon ) return vec3( 0.0, 0.0, - 1.0 );
    
        float step = 0.01;
        float x = sample1( coord + vec3( - step, 0.0, 0.0 ) ) - sample1( coord + vec3( step, 0.0, 0.0 ) );
        float y = sample1( coord + vec3( 0.0, - step, 0.0 ) ) - sample1( coord + vec3( 0.0, step, 0.0 ) );
        float z = sample1( coord + vec3( 0.0, 0.0, - step ) ) - sample1( coord + vec3( 0.0, 0.0, step ) );
    
        return normalize( vec3( x, y, z ) );
      }
    
      void main(){
        vec3 rayDir = normalize( vDirection );
        vec2 bounds = hitBox( vOrigin, rayDir );
    
        if ( bounds.x > bounds.y ) discard;
    
        bounds.x = max( bounds.x, 0.0 );
    
        vec3 p = vOrigin + bounds.x * rayDir;
        vec3 inc = 1.0 / abs( rayDir );
        float delta = min( inc.x, min( inc.y, inc.z ) );
        delta /= 50.0;
    
        for ( float t = bounds.x; t < bounds.y; t += delta ) {
          float d = sample1( p + 0.5 );
    
          if ( d > 0.5 ) {
            // Modified color calculation to emphasize blue and red
            vec3 baseColor = p * 3.0;
            float blueIntensity = baseColor.b;
            float redIntensity = baseColor.r;
            
            // Boost blue and red, reduce green
            color.r = redIntensity * 1.2;
            color.g = baseColor.g * 0.5;
            color.b = blueIntensity * 1.2;
            
            // Ensure color values stay within valid range
            color.rgb = clamp(color.rgb, 0.0, 1.0);
            color.a = 1.;
            break;
          }
    
          p += rayDir * delta;
        }
    
        if ( color.a == 0.0 ) discard;
      }
    `;

      const loader = new VOXLoader(loadingManager);
      loader.load('models/vox/menger.vox', function (chunks) {
        for (let i = 0; i < chunks.length; i++) {
          const chunk = chunks[i];

          const geometry = new THREE.BoxGeometry(1, 1, 1);
          const material = new THREE.RawShaderMaterial({
            glslVersion: THREE.GLSL3,
            uniforms: {
              map: { value: new VOXData3DTexture(chunk) },
              cameraPos: { value: new THREE.Vector3() }
            },
            vertexShader,
            fragmentShader,
            side: THREE.BackSide
          });

          const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          const instanceCount = isMobile ? 25000 : 50000;
          const mesh = new THREE.InstancedMesh(geometry, material, instanceCount);
          mesh.onBeforeRender = function () {
            this.material.uniforms.cameraPos.value.copy(camera.position);
          };

          const transform = new THREE.Object3D();

          for (let i = 0; i < mesh.count; i++) {
            transform.position.random().subScalar(0.5).multiplyScalar(150);
            transform.rotation.x = Math.random() * Math.PI;
            transform.rotation.y = Math.random() * Math.PI;
            transform.rotation.z = Math.random() * Math.PI;
            transform.updateMatrix();

            mesh.setMatrixAt(i, transform.matrix);
          }

          scene.add(mesh);
        }
      });


      const zoomAnimation = () => {
        const targetPosition = new THREE.Vector3(0, 0, 150); // Less zoomed-in final position
        const duration = 7000; // 7 seconds for a slower, more gradual zoom
        const startTime = Date.now();
      
        const animate = () => {
          const currentTime = Date.now();
          const elapsed = currentTime - startTime;
          const progress = Math.min(elapsed / duration, 1);
      
          camera.position.lerpVectors(camera.position, targetPosition, progress * 0.02); // Slower interpolation
      
          if (progress < 1) {
            requestAnimationFrame(animate);
          }
        };
      
        animate();
      };

      setTimeout(zoomAnimation, 1000);

      const animate = () => {
        const delta = clock.getDelta();
        controls.update(delta);
      
        renderer.render(scene, camera);
        animationFrameId = requestAnimationFrame(animate);
      };
    
      // Start the animation loop
      animate();
    };

    const handleResize = () => {
      if (camera && renderer) {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      }
    };

    init();

    window.addEventListener('resize', handleResize);

    const timer = setTimeout(() => {
      setShowContent(true);
    }, 2000);

    
     const createWires = () => {
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute('width', '100%');
      svg.setAttribute('height', '100%');
      wiresRef.current.appendChild(svg);

      const container = document.querySelector('.content-container');
      const rect = container.getBoundingClientRect();
      const colors = ['#FF0000', '#0000FF']; // Red and Blue
      const offset = 5; // Offset to extend wires into the container

      const createWirePair = (startX, startY, endX, endY, delay) => {
        const createWire = (color, offset) => {
          const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
          const controlX = (startX + endX) / 2 + (Math.random() - 0.5) * 100;
          const controlY = (startY + endY) / 2 + (Math.random() - 0.5) * 100;

          let adjustedEndX = endX;
          let adjustedEndY = endY;
          if (adjustedEndX < rect.left) adjustedEndX -= offset;
          else if (adjustedEndX > rect.right) adjustedEndX += offset;
          if (adjustedEndY < rect.top) adjustedEndY -= offset;
          else if (adjustedEndY > rect.bottom) adjustedEndY += offset;

          path.setAttribute('id', `path-${startX}-${startY}-${adjustedEndX}-${adjustedEndY}-${color}`);
          path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${adjustedEndX},${adjustedEndY}`);
          path.setAttribute('stroke', color);
          path.setAttribute('stroke-width', '1'); // Thinner wires
          path.setAttribute('fill', 'none');
          svg.appendChild(path);

          // Enhanced glow effect
          const glow = path.cloneNode();
          glow.setAttribute('stroke', color);
          glow.setAttribute('stroke-width', '3');
          glow.setAttribute('filter', 'url(#enhancedGlow)');
          svg.insertBefore(glow, path);

          // Animation
          const length = path.getTotalLength();
          path.style.strokeDasharray = length;
          path.style.strokeDashoffset = length;
          path.style.animation = `drawWire 2s forwards ${delay}s`;
          glow.style.strokeDasharray = length;
          glow.style.strokeDashoffset = length;
          glow.style.animation = `drawWire 2s forwards ${delay}s, enhancedGlowPulse 3s infinite`;

          // Add data transmission effect
          const dataKnot = document.createElementNS("http://www.w3.org/2000/svg", "circle");
          dataKnot.setAttribute('r', '4');
          dataKnot.setAttribute('fill', color);
          dataKnot.setAttribute('filter', 'url(#enhancedGlow)');

          const animateMotion = document.createElementNS("http://www.w3.org/2000/svg", "animateMotion");
          animateMotion.setAttribute('dur', `${2 + Math.random()}s`);
          animateMotion.setAttribute('repeatCount', 'indefinite');
          animateMotion.setAttribute('keyPoints', '0;1');
          animateMotion.setAttribute('keyTimes', '0;1');

          const animateTransform = document.createElementNS("http://www.w3.org/2000/svg", "animateTransform");
          animateTransform.setAttribute('attributeType', 'XML');
          animateTransform.setAttribute('attributeName', 'transform');
          animateTransform.setAttribute('type', 'scale');
          animateTransform.setAttribute('values', '1;1.5;1');
          animateTransform.setAttribute('dur', '1.5s');
          animateTransform.setAttribute('repeatCount', 'indefinite');

          animateMotion.innerHTML = `<mpath href="#${path.getAttribute('id')}" />`;

          dataKnot.appendChild(animateMotion);
          dataKnot.appendChild(animateTransform);
          svg.appendChild(dataKnot);
        };

        createWire(colors[0], 0); // Red wire
        createWire(colors[1], 1); // Blue wire, slightly offset
      };

      // Add enhanced SVG filter for stronger glow effect
      const filter = document.createElementNS("http://www.w3.org/2000/svg", "filter");
      filter.setAttribute('id', 'enhancedGlow');
      filter.innerHTML = `
        <feGaussianBlur stdDeviation="6" result="coloredBlur"/>
        <feMerge>
          <feMergeNode in="coloredBlur"/>
          <feMergeNode in="coloredBlur"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      `;
      svg.appendChild(filter);

      // Create wire pairs from all sides
      for (let i = 0; i < 3; i++) {
        // Left
        createWirePair(0, Math.random() * window.innerHeight, rect.left, rect.top + Math.random() * rect.height, i * 0.3);
        // Right
        createWirePair(window.innerWidth, Math.random() * window.innerHeight, rect.right, rect.top + Math.random() * rect.height, i * 0.3);
        // Top
        createWirePair(Math.random() * window.innerWidth, 0, rect.left + Math.random() * rect.width, rect.top, i * 0.3);
        // Bottom
        createWirePair(Math.random() * window.innerWidth, window.innerHeight, rect.left + Math.random() * rect.width, rect.bottom, i * 0.3);
      }
    };

    if (showContent) {
      createWires();
    }

    return () => {
      clearInterval(timer);
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
      if (mountRef.current && renderer.domElement) {
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, [showContent]);


  const handleEnterPage = () => {
    window.open('https://linktr.ee/retardAI', '_blank');
  };

  const handleEnterDocs = () => {
    window.open('https://docs.retard.cash', '_blank');
  };

  const handleCopySmartContract = () => {
    if (smartContractRef.current) {
      smartContractRef.current.select();
      document.execCommand('copy');
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const smartContractAddress = "He2EL8hzZKZEQPZw3TvHCRoBeuh4rQzCBxvv3uCCpump"; 

  return (
    <div className="entry-container">
      {isLoading && (
        <div className="loader">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      )}
      <div ref={mountRef} className="animation-container"></div>
      <div className="countdown-header">
        <div className="countdown-timer">{timeRemaining}</div>
      </div>
      <div className="info-text">
        <p> JOIN OUR OPEN BETA ON TELEGRAM: <a href="https://t.me/retardAI" target="_blank" rel="noopener noreferrer">t.me/retardAI</a> </p>
      </div>
      {showContent && (
        <div className="overlay-container">
          <div ref={wiresRef} className="wires-container"></div>
          <div className="content-wrapper">
            <div className="content-container">
              <div className="gif-container">
                <img src="/assets/logo.gif" className="animated-gif" alt="Logo Animation" />
              </div>
              <h1>Welcum 2 RETARD.AI</h1>
              <p>World's first ever LLM trained on retardio brains</p>
            </div>
            <div className="button-container">
              <button className="glitch-button" data-text="Our Socials" onClick={handleEnterPage}>
                <span>Join Socials</span>
              </button>
              <button className="glitch-button" data-text="Check Docs" onClick={handleEnterDocs}>
                <span>Enter Docs</span>
              </button>
            </div>
            <div className="smart-contract-container">
              <input type="text" ref={smartContractRef} value="He2EL8hzZKZEQPZw3TvHCRoBeuh4rQzCBxvv3uCCpump" readOnly />
              <button onClick={handleCopySmartContract}>
                {copied ? "Copied!" : "Copy Contract"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Entry;