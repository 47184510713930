import React, { useEffect, useRef } from 'react';
import './About.css';

const About = () => {
  const sectionRef = useRef(null);
  const wiresRef = useRef(null);

  const createWires = () => {
    if (sectionRef.current && wiresRef.current) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttribute('width', '100%');
      svg.setAttribute('height', '100%');
      wiresRef.current.innerHTML = ''; // Clearing previous wires
      wiresRef.current.appendChild(svg);

      const videoRect = document.querySelector('.video-placeholder-unique').getBoundingClientRect();
      const colors = ['#072792', '#9e0c29']; // Blue and Red colors

      const createWire = (startX, startY, endX, endY, color, delay) => {
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        const controlX = (startX + endX) / 2 + (Math.random() - 0.5) * 100;
        const controlY = (startY + endY) / 2 + (Math.random() - 0.5) * 100;
        path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`);
        path.setAttribute('stroke', color);
        path.setAttribute('stroke-width', '2');
        path.setAttribute('fill', 'none');
        svg.appendChild(path);

        // Glow effect
        const glow = path.cloneNode();
        glow.setAttribute('stroke', color);
        glow.setAttribute('stroke-width', '4');
        glow.setAttribute('filter', 'url(#glow)');
        svg.insertBefore(glow, path);

        // Animation
        const length = path.getTotalLength();
        path.style.strokeDasharray = length;
        path.style.strokeDashoffset = length;
        path.style.animation = `drawWire 2s forwards ${delay}s`;
        glow.style.strokeDasharray = length;
        glow.style.strokeDashoffset = length;
        glow.style.animation = `drawWire 2s forwards ${delay}s, glowPulse 2s infinite`;

        // Data transmission effect
        const dataKnot = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        dataKnot.setAttribute('r', '3');
        dataKnot.setAttribute('fill', color);
        dataKnot.setAttribute('filter', 'url(#glow)');
        const animateMotion = document.createElementNS('http://www.w3.org/2000/svg', 'animateMotion');
        animateMotion.setAttribute('dur', `${2 + Math.random()}s`);
        animateMotion.setAttribute('repeatCount', 'indefinite');
        animateMotion.setAttribute('keyPoints', '0;1');
        animateMotion.setAttribute('keyTimes', '0;1');

        // Pulsating effect
        const animateTransform = document.createElementNS('http://www.w3.org/2000/svg', 'animateTransform');
        animateTransform.setAttribute('attributeType', 'XML');
        animateTransform.setAttribute('attributeName', 'transform');
        animateTransform.setAttribute('type', 'scale');
        animateTransform.setAttribute('values', '1;1.5;1');
        animateTransform.setAttribute('dur', '1.5s');
        animateTransform.setAttribute('repeatCount', 'indefinite');

        animateMotion.innerHTML = `<mpath href="#${path.getAttribute('id')}" />`;
        dataKnot.appendChild(animateMotion);
        dataKnot.appendChild(animateTransform);
        svg.appendChild(dataKnot);
      };

      // Add SVG filter for glow effect
      const filter = document.createElementNS('http://www.w3.org/2000/svg', 'filter');
      filter.setAttribute('id', 'glow');
      filter.innerHTML = `
        <feGaussianBlur stdDeviation="2" result="coloredBlur"/> 
        <feMerge> 
          <feMergeNode in="coloredBlur"/> 
          <feMergeNode in="SourceGraphic"/> 
        </feMerge>
      `;
      svg.appendChild(filter);

      // Set 1 - Touching the left side of the video
// Set 1 - Touching the left side of the video
colors.forEach((color, index) => {
    createWire(
      0,
      videoRect.top + videoRect.height * (0.3 + 0.2 * index) + (0.1 * videoRect.height), //Add offset
      videoRect.left,
      videoRect.top + videoRect.height * (0.3 + 0.2 * index) + (0.1 * videoRect.height), //Add offset
      color,
      0.1 * index
    );
  });
  
  // Set 2 - Touching the top of the video
  colors.forEach((color, index) => {
    createWire(
      0,
      videoRect.top - 150 + 50 * index,
      videoRect.left + videoRect.width * 0.5,
      videoRect.top + (0.1 * videoRect.height), //Add offset
      color,
      0.1 * (2 + index)
    );
  });
  
  // Set 3 - Touching the bottom of the video
  colors.forEach((color, index) => {
    createWire(
      0,
      videoRect.bottom + 150 - 50 * index,
      videoRect.left + videoRect.width * 0.5,
      videoRect.bottom - (0.1 * videoRect.height), //Subtract offset
      color,
      0.1 * (4 + index)
    );
  });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          createWires();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="about-unique" ref={sectionRef}>
      <div className="about-unique-container">
        <div className="headline-unique">
          <div className="video-unique-wrapper">
            <div className="video-placeholder-unique">
              <iframe
                width="640" // Increased width
                height="360" // Increased height
                src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=dzl5bJEjOqA2ox5N"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="content-unique-wrapper">
            <div className="content-unique-text">
              <h2>About Us</h2>
              <p>We are a team of passionate developers and blockchain enthusiasts...</p>
            </div>
            <div className="content-unique-text">
              <h2>Our Mission</h2>
              <p>Our mission is to innovate and lead the future of blockchain technology...</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={wiresRef} className="wires-unique-container"></div>
    </section>
  );
};

export default About;