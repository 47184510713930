import React from 'react';

const Meme = () => {
  return (
    <section className="Meme" style={{ height: '100vh' }}>
      <h2>Meme Gallery</h2>
      <div className="meme-container">
        {/* Add your meme content here */}
      </div>
    </section>
  );
};

export default Meme;