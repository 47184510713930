import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles.css';
import Entry from './components/Entry';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Meme from './components/Meme';
import Roadmap from './components/Roadmap';
import FAQ from './components/FAQ';
import Partners from './components/Partners';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Entry />} />
          <Route path="/home" element={
            <>
              <Header />
              <Hero />
              <About />
              <Meme />
              <Roadmap />
              <FAQ />
              <Partners />
              <Footer />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;