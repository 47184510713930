import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import './Hero.css';

const Hero = () => {
  const containerRef = useRef(null);
  const wiresRef = useRef(null);

  useEffect(() => {
    let container, camera, scene, renderer, geometry, material, mesh;
    const init = () => {
      container = containerRef.current;
      camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 5000);
      camera.position.z = 1000;
      scene = new THREE.Scene();

      const circleGeometry = new THREE.CircleGeometry(1, 6);
      geometry = new THREE.InstancedBufferGeometry();
      geometry.index = circleGeometry.index;
      geometry.attributes = circleGeometry.attributes;

      const particleCount = 50000;
      const translateArray = new Float32Array(particleCount * 3);
      for (let i = 0, i3 = 0, l = particleCount; i < l; i++, i3 += 3) {
        translateArray[i3 + 0] = (Math.random() * 2 - 1) * 0.5;
        translateArray[i3 + 1] = (Math.random() * 2 - 1) * 0.5;
        translateArray[i3 + 2] = (Math.random() * 2 - 1) * 0.5;
      }
      geometry.setAttribute('translate', new THREE.InstancedBufferAttribute(translateArray, 3));

      material = new THREE.RawShaderMaterial({
        uniforms: {
          'map': { value: new THREE.TextureLoader().load('/textures/sprites/circle.png') },
          'time': { value: 0.0 }
        },
        vertexShader: `
        precision highp float;
        uniform mat4 modelViewMatrix;
        uniform mat4 projectionMatrix;
        uniform float time;
        attribute vec3 position;
        attribute vec2 uv;
        attribute vec3 translate;
        varying vec2 vUv;
        varying float vScale;
        void main() {
          vec4 mvPosition = modelViewMatrix * vec4( translate, 1.0 );
          vec3 trTime = vec3(translate.x + time,translate.y + time,translate.z + time);
          float scale = sin( trTime.x * 2.1 ) + sin( trTime.y * 3.2 ) + sin( trTime.z * 4.3 );
          vScale = scale;
          scale = scale * 7.0 + 7.0;
          mvPosition.xyz += position * scale;
          vUv = uv;
          gl_Position = projectionMatrix * mvPosition;
        }
        `,
        fragmentShader: `
        precision highp float;
        uniform sampler2D map;
        varying vec2 vUv;
        varying float vScale;
        vec3 HUEtoRGB(float H){
          H = mod(H,1.0);
          float R = abs(H * 6.0 - 3.0) - 1.0;
          float G = 2.0 - abs(H * 6.0 - 2.0);
          float B = 2.0 - abs(H * 6.0 - 4.0);
          return clamp(vec3(R,G,B),0.0,1.0);
        }
        vec3 HSLtoRGB(vec3 HSL){
          vec3 RGB = HUEtoRGB(HSL.x);
          float C = (1.0 - abs(2.0 * HSL.z - 1.0)) * HSL.y;
          return (RGB - 0.5) * C + HSL.z;
        }
        void main() {
          vec4 diffuseColor = texture2D( map, vUv );
          gl_FragColor = vec4( diffuseColor.xyz * HSLtoRGB(vec3(vScale/5.0, 1.0, 0.5)), diffuseColor.w );
          if ( diffuseColor.w < 0.5 ) discard;
        }
        `,
        depthTest: true,
        depthWrite: true
      });

      mesh = new THREE.Mesh(geometry, material);
      mesh.scale.set(250, 250, 250);
      mesh.position.x = -200;
      scene.add(mesh);

      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setClearColor(0x000000, 0);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setAnimationLoop(animate);
      container.appendChild(renderer.domElement);

      window.addEventListener('resize', onWindowResize);
    }

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }

    const animate = () => {
      const time = performance.now() * 0.0005;
      material.uniforms['time'].value = time;
      mesh.rotation.x = time * 0.2;
      mesh.rotation.y = time * 0.4;
      renderer.render(scene, camera);
    }

    init();

    return () => {
      window.removeEventListener('resize', onWindowResize);
      if (container && renderer.domElement) {
        container.removeChild(renderer.domElement);
      }
      renderer.dispose();
    };
  }, []);

  useEffect(() => {
    const createWires = () => {
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute('width', '100%');
      svg.setAttribute('height', '100%');
      wiresRef.current.appendChild(svg);
      const container = document.querySelector('.hero-content h1');
      const rect = container.getBoundingClientRect();
      const colors = ['#072792', '#9e0c29', '#9e0c29', '#072792'];

      const createWire = (startX, startY, endX, endY, color, delay) => {
        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        const controlX = (startX + endX) / 2 + (Math.random() - 0.5) * 50; // Adjust the randomness
        const controlY = (startY + endY) / 2 + (Math.random() - 0.5) * 50; // Adjust the randomness
        path.setAttribute('id', `path-${startX}-${startY}-${endX}-${endY}`);
        path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`);
        path.setAttribute('stroke', color);
        path.setAttribute('stroke-width', '2'); // Make the wires thinner
        path.setAttribute('fill', 'none');
        svg.appendChild(path);

        // Glow effect
        const glow = path.cloneNode();
        glow.setAttribute('stroke', color);
        glow.setAttribute('stroke-width', '4'); // Double the stroke width for the glow
        glow.setAttribute('filter', 'url(#glow)');
        svg.insertBefore(glow, path);

        // Animation
        const length = path.getTotalLength();
        path.style.strokeDasharray = length;
        path.style.strokeDashoffset = length;
        path.style.animation = `drawWire 2s forwards ${delay}s`;
        glow.style.strokeDasharray = length;
        glow.style.strokeDashoffset = length;
        glow.style.animation = `drawWire 2s forwards ${delay}s, glowPulse 2s infinite`;

        // Data transmission effect
        const dataKnot = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        dataKnot.setAttribute('r', '3'); // Adjust the radius for the data dot
        dataKnot.setAttribute('fill', color);
        dataKnot.setAttribute('filter', 'url(#glow)');
        const animateMotion = document.createElementNS("http://www.w3.org/2000/svg", "animateMotion");
        animateMotion.setAttribute('dur', `${2 + Math.random()}s`); // Adjust the duration randomly
        animateMotion.setAttribute('repeatCount', 'indefinite');
        animateMotion.setAttribute('keyPoints', '0;1');
        animateMotion.setAttribute('keyTimes', '0;1');

        // Add growing and shrinking animation to create a pulsating effect
        const animateTransform = document.createElementNS("http://www.w3.org/2000/svg", "animateTransform");
        animateTransform.setAttribute('attributeType', 'XML');
        animateTransform.setAttribute('attributeName', 'transform');
        animateTransform.setAttribute('type', 'scale');
        animateTransform.setAttribute('values', '1;1.5;1');
        animateTransform.setAttribute('dur', '1.5s');
        animateTransform.setAttribute('repeatCount', 'indefinite');
        
        animateMotion.innerHTML = `<mpath href="#${path.getAttribute('id')}" />`;
        dataKnot.appendChild(animateMotion);
        dataKnot.appendChild(animateTransform);
        svg.appendChild(dataKnot);
      };

      // Add SVG filter for glow effect
      const filter = document.createElementNS("http://www.w3.org/2000/svg", "filter");
      filter.setAttribute('id', 'glow');
      filter.innerHTML = `
        <feGaussianBlur stdDeviation="2" result="coloredBlur"/> 
        <feMerge> 
          <feMergeNode in="coloredBlur"/> 
          <feMergeNode in="SourceGraphic"/> 
        </feMerge>
      `;
      svg.appendChild(filter);

      // Create wires touching the headline
      // Left wire
      createWire(0, rect.top + rect.height / 2, rect.left, rect.top + rect.height / 2, colors[0], 0);

      // Right wire
      createWire(window.innerWidth, rect.top + rect.height / 2, rect.right, rect.top + rect.height / 2, colors[1], 0.2);

      // Top wire
      createWire(window.innerWidth / 2, 0, rect.left + rect.width / 2, rect.top, colors[2], 0.4);
    };

    createWires();
  }, []);

  return (
    <div className="Hero" ref={containerRef}>
      <div className="hero-content">
        <h1>Welcome to Our Amazing Project</h1>
        <p>Discover the future of blockchain technology</p>
      </div>
      <div ref={wiresRef} className="wires-container"></div>
    </div>
  );
};

export default Hero;