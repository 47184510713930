import React from 'react';

const Partners = () => {
  return (
    <section className="Partners" style={{ height: '100vh' }}>
      <h2>Our Partners</h2>
      <div className="partner-logos">
        {/* Add partner logos or names here */}
      </div>
    </section>
  );
};

export default Partners;