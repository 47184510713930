import React from 'react';

const Footer = () => {
  return (
    <footer className="Footer" style={{ height: '100vh' }}>
      <div className="footer-content">
        <p>&copy; 2024 Your Project Name. All rights reserved.</p>
        <nav>
          <a href="#privacy">Privacy Policy</a>
          <a href="#terms">Terms of Service</a>
          <a href="#contact">Contact Us</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;