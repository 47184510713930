import React from 'react';

const FAQ = () => {
  return (
    <section className="FAQ" style={{ height: '100vh' }}>
      <h2>Frequently Asked Questions</h2>
      <dl>
        <dt>What is our project about?</dt>
        <dd>Our project aims to revolutionize the blockchain industry...</dd>
        {/* Add more questions and answers */}
      </dl>
    </section>
  );
};

export default FAQ;