import React from 'react';

const Roadmap = () => {
  return (
    <section className="Roadmap" style={{ height: '100vh' }}>
      <h2>Our Roadmap</h2>
      <ul>
        <li>Q1 2024: Project Launch</li>
        <li>Q2 2024: Beta Testing</li>
        <li>Q3 2024: Mainnet Release</li>
        <li>Q4 2024: Ecosystem Expansion</li>
      </ul>
    </section>
  );
};

export default Roadmap;